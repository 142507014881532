.headerBox {
  width: 100%;
  padding: 24px 32px;
  background: rgba(66, 120, 255, 0.05);
  border-radius: 12px;
  margin-bottom: 24px;
}
.headerBox .name {
  position: relative;
  color: #333;
  font-weight: 500;
  font-size: 19px;
}
.headerBox .name::before {
  position: absolute;
  top: 9px;
  left: -8px;
  display: block;
  content: '';
  width: 4px;
  height: 10px;
  background: #2966FF;
  box-shadow: 0 2px 0 0 #0acca4;
}
.headerBox .desc {
  margin: 16px 0;
  color: #999;
}
.totalText {
  font-weight: 500;
  color: #333;
  text-align: right;
  margin-top: 12px;
}
