.input {
  width: 220px !important;
}
.pwdFormItem {
  position: relative;
}
.pwdFormItem .resetPwdBtn {
  position: absolute;
  left: 338px;
  top: 5px;
}
.radioLeftLabel :global .ant-form-item-label {
  margin-top: -6px;
}
.radioTip {
  font-size: 14px !important;
  margin-left: 24px;
  margin-top: 8px;
  color: #999;
}
.radioTip:first-of-type {
  margin-bottom: 12px;
}
.avatarBox {
  position: absolute;
  right: 80px;
  top: 96px;
}
.avatarBox img {
  width: 60px;
  height: 60px;
}
