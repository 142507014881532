.formItem {
  margin-bottom: 40px;
}
.input {
  width: 240px;
  margin: 12px 0 8px;
}
.inputTip {
  color: #666;
}
.standardType :global .ant-form-item-label {
  margin-top: -5px;
}
.radioTip {
  font-size: 14px !important;
  margin-left: 24px;
  margin-top: 8px;
  color: #999;
}
.radioTip:first-of-type {
  margin-bottom: 16px;
}
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 58px;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  padding-right: 72px;
}
