#loginPage {
  position: static;
  min-width: 1200px;
  height: 100vh;
  overflow-y: hidden;
  background: #F4F6FA;
  display: flex;
}
#loginPage .loginBg {
  position: relative;
  width: 540px;
  height: 100vh;
  background: #2966FF;
}
#loginPage .loginBg img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  height: 800px;
}
#loginPage .loginWrap {
  flex: 1;
  height: 100vh;
  position: relative;
}
#loginPage .leftTopBlock,
#loginPage .rightBottomBlock {
  position: absolute;
  width: 256px;
  height: 256px;
  background: #2966FF;
  opacity: 0.05;
}
#loginPage .leftTopBlock {
  top: 0;
  left: 0;
}
#loginPage .rightBottomBlock {
  right: 0;
  bottom: 0;
}
#loginPage .loginContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  border-radius: 4px;
  background: #fff;
  padding: 24px 34px;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0px 8px 20px rgba(0, 32, 113, 0.04);
}
#loginPage .loginContainer .logo {
  display: block;
  width: 68px;
  height: 68px;
  margin: 0 auto 30px;
}
#loginPage .loginContainer .line {
  width: 312px;
  height: 1px;
  margin-bottom: 32px;
  border-bottom: 1px solid #F3F3F3;
}
#loginPage .loginContainer .tabsWrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#loginPage .loginContainer .tabName {
  color: #666;
  font-size: 18px;
  cursor: pointer;
}
#loginPage .loginContainer .tabName.active {
  position: relative;
  color: #333;
}
#loginPage .loginContainer .tabName.active::after {
  position: absolute;
  top: 31px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  content: '';
  width: 24px;
  height: 4px;
  background: #2966FF;
  border-radius: 1px;
}
#loginPage .loginContainer .phoneWrap {
  margin-top: 44px;
}
#loginPage .loginContainer .phoneWrap .input {
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
}
#loginPage .loginContainer .phoneWrap .input:last-child {
  margin-bottom: 0;
}
#loginPage .loginContainer .btn {
  width: 100%;
  height: 40px;
  margin-top: 24px;
}
.wechat123 {
  cursor: pointer;
  margin-top: 12px;
  font-family: PingFangSC;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #2966FF;
}
