.tagGroupManage {
  display: flex;
}
.tagGroupManage ._left {
  width: 247px;
  box-sizing: border-box;
  padding-right: 16px;
  border-right: 1px solid #ccc;
  height: 550px;
  overflow: auto;
  max-height: 75vh;
}
.tagGroupManage ._left .list {
  margin-top: 8px;
}
.tagGroupManage ._left .item {
  display: flex;
  padding: 11px 17px;
  line-height: 22px;
  cursor: pointer;
  justify-content: space-between;
}
.tagGroupManage ._left .item .ope {
  display: none;
}
.tagGroupManage ._left .item .ope .icon {
  color: #BFBFBF;
}
.tagGroupManage ._left .item .visible {
  display: block;
}
.tagGroupManage ._left .item .name {
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  width: 161px;
  color: #666666;
}
.tagGroupManage ._left .item:hover {
  background: #F3F6FA;
}
.tagGroupManage ._left .item:hover .ope {
  display: block;
}
.tagGroupManage ._left .item.active {
  background: #EEF3FF;
  color: #2966FF;
}
.tagGroupManage ._right {
  flex: 1;
  padding: 0 16px;
}
.tagGroupManage ._right .head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.tagGroupManage ._right .head .search {
  display: flex;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: #F3F6FA;
}
