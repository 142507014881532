.scoreWrap {
  border: 1px solid #e8e8e8;
  padding: 24px;
  margin-top: 12px;
}
.scoreWrap .inputTip {
  color: #999;
  margin-top: 8px;
}
.scoreWrap .scoreTable :global .ant-input,
.scoreWrap .scoreTable :global .ant-input-number-input {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.scoreWrap .scoreTable :global .ant-table-row {
  background: #fff !important;
}
.scoreWrap .scoreTable :global .ant-table-row td {
  border-bottom: 1px solid #e8e8e8 !important;
}
.scoreWrap :global .ant-input-number-handler-wrap {
  display: none;
}
:global .score-form-item > .ant-form-item-label {
  margin-top: -5px;
}
:global .tag-select .ant-select-tree-checkbox-disabled {
  display: none;
}
.tagWrap {
  padding: 16px;
}
.totalText {
  font-weight: 500;
  color: #333;
  text-align: right;
  margin-top: 12px;
}
