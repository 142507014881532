@keyframes slideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
.schoolSelectWrap {
  position: absolute;
  top: 32px;
  width: 304px;
  background: #fff;
  z-index: 10;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.schoolSelectWrap .input {
  margin: 16px;
}
.schoolSelectWrap .main {
  max-height: 264px;
  overflow-y: auto;
  padding: 16px;
}
.schoolSelectWrap .main .listItem {
  margin-bottom: 16px;
}
.schoolSelectWrap .footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #E8E8E8;
  height: 48px;
  padding-right: 24px;
}
.schoolSelectWrap.open {
  animation-name: slideUpIn;
  animation-duration: 0.3s;
}
.schoolSelectWrap.hidden {
  display: none;
}
