.ReportExamModal .headerBox {
  width: 100%;
  padding: 24px 38px;
  background: rgba(66, 120, 255, 0.05);
  border-radius: 12px;
  margin-bottom: 12px;
}
.ReportExamModal .headerBox .title {
  position: relative;
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 25px;
  color: #333333;
}
.ReportExamModal .headerBox .title::after {
  background: linear-gradient(to bottom, #2966FF 0, #2966FF 10px, #0ACCA4 10px, #0ACCA4 100%);
  content: '';
  width: 4px;
  height: 12px;
  position: absolute;
  left: -10px;
  top: 5px;
}
.ReportExamModal .headerBox .desc {
  font-family: PingFangSC;
  font-size: 14px;
  line-height: 16px;
  margin: 16px 0;
  color: #999999;
}
.ReportExamModal .xmTable {
  border: 1px solid #E8E8E8;
  text-align: left;
}
.ReportExamModal .xmTable .header {
  display: flex;
}
.ReportExamModal .xmTable .header .item {
  text-align: left;
  font-weight: normal;
  background: #F7F8F9;
  color: #333333;
}
.ReportExamModal .xmTable .item {
  padding: 10px 12px;
  font-size: 14px;
  line-height: 18px;
  width: 25%;
  text-align: left;
}
.ReportExamModal .xmTable .body .list {
  border-bottom: 1px solid #E8E8E8;
  display: flex;
}
.ReportExamModal .xmTable .body .list .item input {
  border: none;
}
.ReportExamModal .xmTable .body .list .item input:hover,
.ReportExamModal .xmTable .body .list .item input:focus,
.ReportExamModal .xmTable .body .list .item input:focus-visible {
  border: none;
  outline: none;
}
.ReportExamModal .xmTable .body .list.last {
  border: none;
}
.ReportExamModal .fileListBox {
  margin-top: 12px;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 16px;
}
.ReportExamModal .fileListBox .total {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  color: #333333;
}
.ReportExamModal .fileListBox .fileList .fileBox {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.ReportExamModal .fileListBox .fileList .fileBox:last-child {
  margin-bottom: 0px;
}
.ReportExamModal .fileListBox .fileList .fileBox .num {
  margin-right: 16px;
  width: 20;
}
.ReportExamModal .fileListBox .fileList .fileBox .name {
  flex: 1;
}
.ReportExamModal .fileListBox .fileList .fileBox .name img {
  width: 16px;
}
.ReportExamModal .fileListBox .fileList .fileBox .operate {
  width: 89px;
  font-size: 14px;
  line-height: 16px;
  color: #2966FF;
}
.ReportExamModal .fileListBox .fileList .fileBox .operate span {
  cursor: pointer;
}
.ReportExamModal .fileListBox .fileList .fileBox .operate .line {
  display: inline-block;
  width: 1px;
  height: 14px;
  background: #BFBFBF;
  margin: 0 6px;
  position: relative;
  top: 3px;
}
.editNameContent {
  width: 320px;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.editNameContent .title {
  margin-bottom: 12px;
}
.editNameContent .foster {
  margin-top: 12px;
  text-align: right;
}
