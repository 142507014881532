.topTips {
  color: #999;
  margin-bottom: 40px;
}
.topTips .tipItem {
  margin-right: 24px;
}
.summaryWrap {
  position: relative;
  display: flex;
  height: 115px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-bottom: 16px;
  width: fit-content;
  margin-bottom: 32px;
}
.summaryWrap .standardTypeTag {
  position: absolute;
  left: 6px;
  top: 12px;
  border-radius: 2px;
  border: 1px solid;
  padding: 0 8px;
}
.summaryWrap .summaryBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 280px;
  position: relative;
}
.summaryWrap .summaryBox::after {
  position: absolute;
  right: 0;
  display: block;
  content: '';
  height: 40px;
  border-left: 1px solid #e8e8e8;
}
.summaryWrap .summaryBox:last-of-type::after {
  height: 0;
}
.summaryWrap .summaryBox .value {
  font-family: 'PT Sans';
  font-size: 26px;
  font-weight: bolder;
}
.summaryWrap .summaryBox .total {
  font-size: 12px;
  color: #999;
  margin-bottom: 16px;
}
.summaryWrap .summaryBox .name {
  color: #666;
}
.description {
  display: flex;
}
.description .name {
  white-space: nowrap;
}
