.xm_header {
  height: 60px;
  overflow: visible;
}
.xm_header .box {
  height: 97px;
  background-color: #2966FF;
  display: flex;
  justify-content: space-between;
}
.xm_header .box .userInfo {
  float: right;
  display: flex;
  padding: 18px 16px;
  color: #fff;
  line-height: 24px;
  width: auto;
  height: 35px;
  cursor: pointer;
}
.xm_header .box .userInfo img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.xm_header .box .userInfo .name {
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0 4px 0 10px;
}
.xm_header .box .userInfo .icon {
  font-size: 12px;
  line-height: 24px;
}
.xm_header .box .schoolName {
  color: #fff;
  line-height: 65px;
  margin-left: 16px;
}
.xm_header .bag {
  height: 53px;
  background: linear-gradient(180deg, #2966FF 0%, rgba(41, 102, 255, 0.82) 29.13%, rgba(41, 102, 255, 0.58) 54.88%, rgba(41, 102, 255, 0.27) 77%, rgba(7, 78, 255, 0.0001) 100%);
}
.header_dropdown {
  width: 275px;
  position: relative;
}
.header_dropdown .ant-divider-horizontal {
  margin: 12px 0;
}
.header_dropdown .icon {
  color: #999;
}
.header_dropdown .setInfo {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.header_dropdown .setInfo .name {
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 6px;
}
.header_dropdown .setInfo .account {
  font-size: 14px;
  line-height: 16px;
  color: #999999;
}
.header_dropdown .setInfo .shezhi {
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  text-align: right;
  color: #666666;
  padding-top: 16px;
}
.header_dropdown .loginout {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}
.header_dropdown .loginout .text {
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}
.header_dropdown .loginout .text .icon {
  color: #BFBFBF;
  margin-right: 8px;
}
