.map {
  position: relative;
}
.map .mask {
  position: absolute;
  z-index: 99;
}
.map .container {
  position: absolute;
  top: 16px;
  left: 16px;
}
.map .amap-marker-label {
  border: none;
  background: transparent;
}
.map .amap-marker-label .info {
  z-index: 999;
  width: 20rem;
  padding: 0.75rem 1.25rem;
  margin-left: 1.25rem;
  position: fixed;
  top: -2.8rem;
  left: -10rem;
  background-color: #fff;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #666;
  box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.map #searchResultPanel {
  max-height: 300px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.map #searchResultPanel .address-item {
  width: 468px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  padding: 0 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: nowrap;
}
.map #searchResultPanel .address-item:hover {
  background: #f3f6fa;
}
.map #searchResultPanel .address-item .title {
  font-size: 14px;
  color: #333333;
  line-height: 36px;
}
.map #searchResultPanel .address-item .sub-title {
  font-size: 12px;
  color: #999999;
  line-height: 36px;
}
.map #searchResultPanel .no-list {
  padding-top: 32px;
  width: 250px;
  height: 160px;
  background: #fff;
  text-align: center;
  color: #999;
}
.map #searchResultPanel .no-list img {
  width: 60px;
  height: 60px;
}
.map #searchResultPanel .no-list p {
  margin-top: 12px;
}
