.xmAside .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 8px;
}
.xmAside .logo img {
  height: 24px;
}
.xmAside .logo .foldBtn {
  color: #434F5D;
  cursor: pointer;
}
.xmAside .iconImg {
  width: 18px;
  height: 18px;
}
.xmAside .menuItem .menuItemTitle {
  position: relative;
}
.xmAside .menuItem .menuRedDot {
  position: absolute;
  right: -6px;
  top: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #FF4F4F;
}
