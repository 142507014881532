.chooseText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  color: #666;
  cursor: pointer;
}
.schoolWrap {
  position: relative;
}
.schoolForm :global .ant-form-item-label {
  margin-top: -5px;
}
.schoolList {
  width: 340px;
  margin-top: 14px;
  border: 1px solid #e8e8e8;
  padding: 12px 0;
}
.schoolList .title {
  color: #333;
  margin: 0 16px 16px;
}
.schoolList .listContainer {
  max-height: 204px;
  overflow-y: auto;
  padding: 0 16px;
}
.schoolList .schoolItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.schoolList .schoolItem:last-of-type {
  margin-bottom: 0;
}
.schoolList .schoolItem .schoolName {
  color: #666;
}
.schoolSelect .footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  border-top: 1px solid #e8e8e8;
  padding: 0 24px;
}
