.userInfo {
  width: 480px;
}
.userInfo .title {
  padding-left: 12px;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 12px;
  /* identical to box height */
  color: #333333;
}
.userInfo .title::after {
  background: linear-gradient(to bottom, #2966FF 0, #2966FF 10px, #0ACCA4 10px, #0ACCA4 100%);
  content: '';
  width: 4px;
  height: 12px;
  position: absolute;
  left: 0px;
  top: 5px;
}
.userInfo .ant-form-item label {
  margin-right: 12px;
}
.userInfo .avatorBox label {
  line-height: 60px;
  height: 60px;
}
.userInfo .avatorBox img {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.userInfo .content {
  display: flex;
}
.userInfo .content .operate {
  margin-left: 12px;
  color: #2966FF;
  cursor: pointer;
}
